import React from 'react';
import ContentNavigationLink from './Link';
import styled from 'styled-components';

const isSubLinkActive = (links, currentPathName) =>
  links.some(link => link.to === currentPathName);

const ContentNavigationSubmenu = ({
  links,
  text,
  currentPathName,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = React.useState(
    isSubLinkActive(links, currentPathName)
  );

  const toggleSubLinks = e => {
    e.stopPropagation();
    setIsOpen(prev => {
      return !prev;
    });
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <Item
      className={`dropdown has-children ${isOpen ? 'open' : 'closed'}-menu`}
      onClick={toggleSubLinks}
    >
      <Title>{text}</Title>
      <Submenu>
        {links.map((link, index) => (
          <ContentNavigationLink key={index} text={link.title} to={link.to} />
        ))}
      </Submenu>
    </Item>
  );
};

const Item = styled.li`
  &.active {
    ul {
      display: block;
      li {
        background-color: #0094a5;
        font-weight: 400;
      }
    }
  }
`;

const Title = styled.label`
  position: relative;
  display: block;
  padding: 8px 0;
  float: left;
  width: 100%;
  color: #fff;
`;

const Submenu = styled.ul`
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  max-height: 0;
  overflow: hidden;
  width: 100%;
  clear: both;
`;

export default ContentNavigationSubmenu;
