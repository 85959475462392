import React from 'react';
import Text from '../../../common/Text';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Link from '../../../common/Link';

const Row = styled(Grid)`
  border-radius: 5px;
  background-color: #e6e6e6;
`;

export default ({ title, url }) => {
  return (
    <Grid item xs={12}>
      <Row container spacing={2}>
        <Grid
          item
          xs={4}
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Text>{title}</Text>
        </Grid>
        <Grid item xs={8} align="right">
          <Link to={url} target="_blank" rel="noopener noreferrer">
            Download
          </Link>
        </Grid>
      </Row>
    </Grid>
  );
};
