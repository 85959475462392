import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { SubHeadline } from '../../../common/Headline';
import Row from './Row';
import Breadcrumbs from '../../Breadcrumbs';

export default ({ application, information }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (application && application.programmeDetails) {
      setBreadcrumbs([
        {
          label: 'Applications',
          to: '/mybunac/applications',
        },
        {
          label: application.programmeDetails.title,
        },
        {
          label: 'Vital Information',
        },
      ]);
    }
  }, [application]);

  if (!application || !information) {
    return <></>;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <SubHeadline>Vital Information</SubHeadline>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      {information.map(({ title, url }) => (
        <Row key={`${title}/${url}`} title={title} url={url} />
      ))}
    </Grid>
  );
};
