import React, { useEffect, useState } from 'react';
import ContentNavigation from '../Navigation/Content';
import formatSlug from '../../lib/slugFormatter';
import { useMyBunac } from '../../hooks/useMyBunac';

const Navigation = () => {
  const [navigationData, setNavigationData] = useState([]);
  const { profile } = useMyBunac();

  useEffect(() => {
    if (profile && profile.role) {
      const isAgentOrSupplier = ['agent', 'supplier'].includes(profile.role);
      const navigationData = [
        {
          title: isAgentOrSupplier ? 'Deal Overview' : 'Applications',
          to: formatSlug(['mybunac']),
        },
        {
          title: 'Profile',
          to: formatSlug(['mybunac', 'profile']),
        },
        {
          title: 'Logout',
          to: formatSlug(['mybunac', 'logout']),
        },
      ];

      setNavigationData(navigationData);
    }
  }, [profile]);

  return <ContentNavigation links={navigationData} parentSlug="/" />;
};

export default Navigation;
