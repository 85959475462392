import React, { useEffect, useState } from 'react';
import Layout, { Content } from '../../components/Layout';
import { useAuth } from '../../hooks/useAuth';
import { useApplication } from '../../hooks/useApplication';
import Navigation from '../../components/mybunac/navigation';
import ApplicationInformation from '../../components/mybunac/Application/Information';
import styled from 'styled-components';

const ContentLayout = styled.div`
  display: block;
  height: 100%;
`;

const Information = ({ data, pageContext }) => {
  const { isAuthenticated } = useAuth();
  const { application, setApplicationId } = useApplication();
  const [vitalInformation, setVitalInformation] = useState([]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const applicationId = window.location.hash.substr(1);
    setApplicationId(applicationId);

    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    const vitalInformation =
      application && application.programmeDetails
        ? application.programmeDetails.vitalInformation
        : [];
    setVitalInformation(vitalInformation);
  }, [application]);

  return (
    <Layout title={'Information'}>
      <Navigation />
      <Content>
        <ContentLayout>
          <ApplicationInformation
            application={application}
            information={vitalInformation}
          />
        </ContentLayout>
      </Content>
    </Layout>
  );
};

export default Information;