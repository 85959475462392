import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  min-height: 25px;
  color: rgba(0, 0, 0, 0.47);
  font-family: Arial;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 32px;
`;

export default ({ children, ...props }) => <Text {...props}>{children}</Text>;
