import React from 'react';
import _Link from '../../common/Link';
import styled from 'styled-components';

function ContentNavigationLink(props) {
  return (
    <Item>
      <Link to={props.to} activeClassName="active">
        {props.text}
      </Link>
    </Item>
  );
}

const Item = styled.li`
  height: auto;
  overflow: hidden;
  text-transform: capitalize;
  -webkit-user-select: none;
  user-select: none;
  display: block &.active {
    background-color: #222;
    font-weight: 600;
  }
`;

const Link = styled(_Link)`
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  padding: 8px 0 8px 16px
  text-decoration: none;
  &:link, &:visited {
    color: rgba(255, 255, 255, .9);
    text-decoration: none;
    display: block;
  }
  &:active, &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    color: #fff;
  }
  &.active {
    background-color: #222;
    font-weight: 600;
  }
`;

export default ContentNavigationLink;
