import React from 'react';
import Breadcrumb from './Breadcrumb';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

const Arrow = styled.div`
  letter-spacing: 0.1px;
  font-size: 13px;
  font-family: Arial;
  color: #adadad;
  display: block;
  margin: 0 5px;
`;

const Breadcrumbs = ({ breadcrumbs = [] }) => {
  return (
    <Container>
      {breadcrumbs &&
        breadcrumbs.map((breadcrumb, index) => {
          return (
            <>
              <Breadcrumb
                key={`breadcrumb_${index}`}
                breadcrumb={breadcrumb}
                className={index === breadcrumbs.length - 1 ? 'isActive' : ''}
              />
              {index < breadcrumbs.length - 1 && <Arrow>{'>'}</Arrow>}
            </>
          );
        })}
    </Container>
  );
};

export default Breadcrumbs;
