import styled from 'styled-components';

const SubHeadline = styled.div`
  height: 28px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Arial;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 16px;
`;

const BlockTitle = styled.span`
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
`;

const Headline = styled.h1`
  color: #000000;
  font-family: Arial;
  font-size: 40px;
  letter-spacing: 0.71px;
  line-height: 45px;
  margin-bottom: 30px;
`;

export default Headline;
export { BlockTitle, SubHeadline, Headline };
