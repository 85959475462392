import React from 'react';
import ContentNavigationLink from './Link';
import ContentNavigationSubmenu from './Submenu';
import './style.css';
import styled from 'styled-components';

const ContentNavigation = ({ links, theme, currentPathName }) => {
  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.screen.width <= 960) {
      return;
    }
    const progNav = document.getElementById('ctl00_progNav');
    const fixNavigationOnScroll = () => {
      if (window.scrollY >= 70) {
        progNav.style.position = 'fixed';
        progNav.style.top = '80px';
      } else {
        progNav.style.position = 'absolute';
        progNav.style.top = '70px';
      }
    };
    if (progNav) {
      window.addEventListener('scroll', fixNavigationOnScroll);
    }
    return () => {
      if (progNav) {
        window.removeEventListener('scroll', fixNavigationOnScroll);
      }
    };
  }, []);

  return (
    <Container
      id="ctl00_progNav"
      className={['programme-navigation', `theme-${theme}`]}
    >
      <Nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-collapse" id="programme-navigation">
            <List className="cd-accordion-menu animated">
              {links &&
                links.map((link, index) => {
                  if (link.subLinks && link.subLinks.length) {
                    return (
                      <ContentNavigationSubmenu
                        currentPathName={currentPathName}
                        key={index}
                        text={link.title}
                        links={link.subLinks}
                      />
                    );
                  }
                  return (
                    <ContentNavigationLink
                      key={index}
                      text={link.title}
                      to={link.to}
                    />
                  );
                })}
            </List>
          </div>
        </div>
      </Nav>
    </Container>
  );
};

const Container = styled.div`
  overflow-y: auto;
  background-color: #0094a5;
  -webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 21;

  &.theme-usa {
    background-color: #f63933;
  }
`;

const Nav = styled.nav`
  background-color: #0094A5
  display: block
`;

const List = styled.ul`
  max-height: 50000px;
  overflow: hidden;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
`;

export default ContentNavigation;
