import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  letter-spacing: 0.1px;
  font-size: 13px;
  font-family: Arial;
  color: #adadad;
  display: block;

  &.isActive {
    color: #595959;
    pointer-events: none;
  }
`;

const BreadCrumb = ({ breadcrumb, className }) => {
  return <Container className={className}>{breadcrumb.label}</Container>;
};

export default BreadCrumb;
